import React from "react"
import { Helmet } from "react-helmet"
import "../styles/app.sass"
import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ children }) => { 
  return(
    <>
      <Helmet>
        <title>Baker Design Co. | Columbus Ohio Video Production</title>
      </Helmet>
      <Header />
        <main className="main-wrap">
          {children}
        </main>
      {/* <Footer /> */}

    </>
  )
}
export default Layout