import React from "react";
// import { useDimensions } from "../utlities/use-dimensions";
import SiteLogo from "./logo";
import SiteNav from "./nav"
// import { useCycle, motion } from "framer-motion"

const Header = (data) => {

  // const containerRef = useRef(null);
  console.log(data)
 
  return (
    <header className="header">

      <a href="/" className="logo">
        <SiteLogo />
      </a>
     
    {/* <a href="/contact" className="button outline">
      Let's Talk
    </a> */}
    </header>
  )
}
export default Header






