import * as React from "react"
import Arrow from './arrow'
import { useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
const Grid = ({ props }) => {
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
  return (

    <div className="grid-wrap">

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: .45, duration: .45 }}
        className={`grid-content ${props.grid_content_one.grid_col}`}>
        <div className="grid-item">
          <h2>{props.grid_content_one.grid_title}</h2>
          <div class="video-grid two-col">
            {props.grid_content_one.blocks.map((block, index) =>
              <motion.div key={index} className="list-item">
                {console.log(block.video)}
                {block.Header !== '' &&
                  <>
                    <div class="video-wrap">
                      <iframe
                        src={block.video}
                        title=""
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      />
                    </div>
                    <h3>{block.title}</h3>
                  </>

                }
              </motion.div>
            )}
          </div>
        </div>



      </motion.div>
    </div>
  )
}
export default Grid
