import * as React from "react"
import { Link } from "gatsby"
const Footer = () => {

  return(
    <footer className="site-footer">
      <nav className="footer-nav">

    
        <Link to="mailto:info@bakerdesign.co">info@bakerdesign.co</Link>
      </nav>
    </footer>
  )

}

export default Footer