import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
// import Banner from "../components/banner"
import Hero from "../components/hero"
import Grid from "../components/grid"
// import Mosaic from "../components/mosaic"
import Layout from "../layouts/index"
import Footer from '../components/footer';
// import Carousel from '../lib/carousel'
const HomePage = ({ data }) => {
  const dataBase = data.mdx.frontmatter
  return (
    <Layout>
      <Hero paragraph={dataBase.description} title={dataBase.title}></Hero>
      <Grid
        props={data.mdx.frontmatter}
      />
      <Footer />

    </Layout>
  )
}
export default HomePage
export const pageQuery = graphql`
query homeuery {
  
  mdx(slug: {eq: "home"}) {
    frontmatter {
      title
      grid_content_one {
        grid_col
       
        grid_title
        blocks {
          # copy
          video
          title
        
        }
      }
    }
  }
}`