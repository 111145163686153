import * as React from "react"

const SiteLogo = () => {

  return (

    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478.38 28.61">
      <path class="cls-1" d="M1.15,16.94l29.04.42v-1.61h-12.69C5.52,15.75,0,13.58,0,8.87,0,2.63,5.22.59,21.27.59h21.86v9.72l-29.12.34v1.44c5.99,0,10.87.08,14.86.25,12.69.55,15.62,1.95,15.62,7.56,0,5.31-4.75,7.85-14.77,7.85H1.15v-10.82Z" />
      <path class="cls-1" d="M47.45,14.22c0-10.23,6.45-13.63,16.6-13.63h29.04v12.4h-26.62v3.44h26.62v11.33h-27.17c-11.46,0-18.47-3.1-18.47-13.54Z" />
      <path class="cls-1" d="M95.2,14.18c0-9.55,7.56-14.18,23.22-14.18s23.13,4.63,23.13,14.18-7.51,14.43-23.13,14.43-23.22-4.71-23.22-14.43ZM124.74,16.6v-4.8h-12.82v4.8h12.82Z" />
      <path class="cls-1" d="M156.57,11.8h-13.33V.59h41.9v11.21h-13.33v15.96h-15.24v-15.96Z" />
      <path class="cls-1" d="M200.16,11.8h-13.33V.59h41.9v11.21h-13.33v15.96h-15.24v-15.96Z" />
      <path class="cls-1" d="M243.58.59h27.59c11.72,0,14.77,1.4,14.77,6.79,0,4.2-3.01,5.82-11.72,6.2v.38h.93c9.17,0,11.72,1.44,11.72,6.54,0,5.6-3.27,7.26-14.43,7.26h-28.87V.59ZM269.6,11.76v-3.31h-10.91v3.31h10.91ZM270.11,20.89v-4.08h-11.42v4.08h11.42Z" />
      <path class="cls-1" d="M300.5.59h24.92l12.35,27.17h-16.51l-1.74-3.69h-13.12l-1.74,3.69h-16.51l12.35-27.17ZM316.07,16.81l-2.76-5.77h-.72l-2.76,5.77h6.24Z" />
      <path class="cls-1" d="M361.95,20.5h-5.56v7.26h-15.24V.59h15.24v8.28h2l8.07-8.28h18.68l-11.21,11.12,12.06,16.05h-18.68l-5.35-7.26Z" />
      <path class="cls-1" d="M390.39.59h38.16v8.49h-23.05v1.83h22.16v6.24h-22.16v2.12h23.05v8.49h-38.16V.59Z" />
      <path class="cls-1" d="M434.91.59h28.57c11.25,0,14.9,2.38,14.9,9.76,0,6.83-5.31,8.7-24.83,8.7h-1.4v.85h25.47v7.85h-23.47l-4.42-8.7v8.7h-14.81V.59ZM461.19,14.14v-3.69h-11v3.69h11Z" />
    </svg>
  )
}

export default SiteLogo
